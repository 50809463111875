import axios from "axios";
import Url from "../../constants/Url";

const url = `${Url.Api}`;


export const loadPlaces = async(token) =>{
    console.log("loadPlaces : ", token);

        const path = "place/all";
        try {

            const response =   await axios.get(`${url}/${path}`,{
            headers:{
                'Accept':"application/json",
                'Content-Type':"application/json",
                'Authorization':`Bearer ${token}`
            }
            });
            return response.data;

        } catch (error) {
            console.log(error.message);
            return error.message;
        }
 
}

export const loadDirectionsByPlace = async(p, token) => {
            console.log("loadDirectionsByPlace : ",p.value.id);

    const path = "direction/byPlaceId/"+`${p.value.id}`;

    try {
        const response = await axios.get(`${url}/${path}`, {
            headers:{
                'Accept':"application/json",
                'Content-Type':"application/json",
                'Authorization':`Bearer ${token}`
            }
        });
        return response.data;

    } catch (error) {
        console.log(error.message);
        return error.message;
    }

}