import axios from "axios";
import Url from "../../constants/Url";

const url = `${Url.Api}`;


export const authenticateUser = async(email, password)=> {


    try {
        const response = await axios.post(`${url}/user/login/`,  { email, password }, );
        console.log(response.data);
        if(response.data.token!==null){
            const user = JSON.stringify(response.data.user);
            console.log(user);
            
            return response.data;
        }else{
            return null;
        }
        

    } catch (error) {
        console.log("authenticateUser - catch");
        console.log(error.message);
        return null;
    }


}

