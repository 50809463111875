import React from 'react';
import './css/Home.css';
import { Col, Container, Image, Row } from 'react-bootstrap';
import Url from '../constants/Url';
import FuturaM from '../components/FuturaM';
import { Link } from 'react-router-dom';


const AccountDeletionRequest = () => {



    return(
        <Container id='main'>
            <Row>
                <Col xs={12}>
                    <Image id='goodparkImg' src={`${Url.Cdm}LogolongB.png`}  alt='Logo'/>
                </Col>
                <Col xs={12}>
                    
                    <FuturaM color="white">Account deletion request page : under construction...</FuturaM>
                    
                </Col>
            </Row>
        </Container>
    )
}

export default AccountDeletionRequest;