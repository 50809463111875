import { autoBatchEnhancer } from "@reduxjs/toolkit";
import { FormLabel } from "react-bootstrap"
import color from "../constants/color";

const FuturaM = props => {

    return(
        <FormLabel style={{...props.style, fontFamily:'Futura-Medium', 
        color:props.color?props.color:color.secondary}}>{props.children}</FormLabel>
    )
}

export default FuturaM;