import React, { useState } from 'react';
import './css/LogIn.css';
//MDB
import {
    MDBBtn,
    MDBContainer,
    MDBCard,
    MDBCardBody,
    MDBInput,
    MDBIcon,
    MDBRow,
    MDBCol,
    MDBCheckbox
  }
  from 'mdb-react-ui-kit';
import {  Col, Image, Button } from 'react-bootstrap';
//Constants
import Url from '../constants/Url';
import color from '../constants/color';
//Access to the global state
import { useDispatch, useSelector } from 'react-redux';
//Actions
import { logIn } from '../redux/slices/users.slice';
import { setPlaces, setDirections, setSelectedPlace } from '../redux/slices/places.slice';
//Api requests
import axios from 'axios';
//Navigation
import { useNavigate } from 'react-router-dom';
//Components
import { setUserLoggedIn } from "../redux/slices/users.slice";
import { authenticateUser } from '../redux/services/users';
//Service & Slice
import { setJwt } from "../redux/slices/users.slice";
import { loadPlaces, loadDirectionsByPlace } from '../redux/services/places';

const LogIn = props => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(state=>state.users.userLoggedIn); 
    

    const [ token, setToken ] = useState(useSelector(state => state.users.jwt));
    const [ isLoading, setIsloading ] = useState(true);
    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ message, setMessage ] = useState('');



    const login = async() =>{
        

            //console.log("***email :***"+email);
            //console.log("***password :***"+password);
            try {
                const response = await authenticateUser(email, password);
                //console.log(response);
                
                dispatch(setUserLoggedIn(response.user));
                let t = response.token;
                dispatch(setJwt(t));

                //console.log("***token :***"+t);
                if(response !== null){
                    await getPlaces(t);
                    navigate('/BackOffice');
                }else{
                    setMessage("Email/Password invalid");
                }
            } catch (error) {
                console.log(error.message);
                navigate('/');
            }

    }

    const getPlaces = async(token) =>{
        const response = await loadPlaces(token);
        console.log("getPlaces : ",response);
        dispatch(setPlaces(response));
        dispatch(setSelectedPlace(response[0]));
    }

   



    return(

        <div id='logInContainer'>
            <MDBContainer fluid className='my-5' >
                <MDBRow className='g-0 align-items-center' >
                    <MDBCol col='6'>
                        <MDBCard className='my-5 cascading-right' style={{background: 'white',  backdropFilter: 'blur(30px)', minWidth:'30vw'}}>
                        <MDBCardBody className='p-5 shadow-5 text-center'>
                            <h2 className="fw-bold mb-5" style={{color:color.primary}}>
                            <Col xs={12}>
                                <Image id='logoImgRed' src={`${Url.Cdm}LogolongR.png`}  alt='Logo'/>
                            </Col>
                            </h2>
                                    <h5 style={{ color:color.primary, marginBottom:'5vh',
                                    fontSize:'2vh'}}>{message}</h5>
                            <MDBInput wrapperClass='mb-4' label="Email" value={email} id='form3' type='email'
                            onChange={(e)=>setEmail(e.target.value)}/>
                            <MDBInput wrapperClass='mb-4' label="Password" value={password} id='form4' type='password'
                            onChange={(e)=>setPassword(e.target.value)}/>
{/*                             <div className='d-flex justify-content-center mb-4'>
                            <MDBCheckbox name='flexCheck' value='' id='flexCheckDefault' label='Subscribe to our newsletter' />
                            </div> */}
                            <Button type='button' onClick={()=>login()}
                            style={{backgroundColor:color.primary,marginTop:'5vh'}}>Log In</Button>
                        </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </div>

    )
}

export default LogIn;

