import axios from "axios";
import Url from "../../constants/Url";

const url = `${Url.Api}`;


export const directionsByPlaceId = async(jwt, placeId)=> {


    try {
        const response = await axios.get(`${url}/direction/byPlaceId/${placeId}`, {
                headers:{
                    'Accept':"application/json",
                    'Content-Type':"application/json",
                    'Authorization':`Bearer ${jwt}`
                } 
        } );
        console.log(response.data);
        if(response.data!==null){
            const directions = JSON.stringify(response.data);
            console.log(directions);
            
            return directions;
        }else{
            return null;
        }
        

    } catch (error) {
        console.log("get directions by place id - catch");
        console.log(error.message);
        throw error;
    }


}

export const createNewDirection = async(jwt, direction)=>{

    try {
        const response = await axios.post(`${url}/direction/create/`,direction,
            {
                headers:{
                    'Accept':"application/json",
                    'Content-Type':"application/json",
                    'Authorization':`Bearer ${jwt}`
                } 
            }
        );
        console.log("directin created :", response.data);
        return response.data;
    } catch (error) {
        console.log(error.message);
        throw error;
    }
}

export const deleteDirectionById = async(jwt, id)=> {
    console.log("token : ", jwt);
    console.log("delete direction id : ", id);
    try {
        const response = await axios.delete(`${url}/direction/delete/${id}`, {
            headers:{
                    'Accept':"application/json",
                    'Content-Type':"application/json",
                    'Authorization':`Bearer ${jwt}`
            }
        } );
        return response.data.message;
        

    } catch (error) {
        console.log("get directions by place id - catch");
        console.log(error.message);
        return null;
    }


}