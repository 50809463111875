import React from 'react';
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBRow,
  MDBCol
} from 'mdb-react-ui-kit';
import Url from '../constants/Url';
import color from '../constants/color';
import { Link } from 'react-router-dom';
import { setUserLoggedIn } from '../redux/slices/users.slice';
import { useDispatch } from 'react-redux';

const Navbar = () =>{

    const dispatch = useDispatch();

    return(
    <>
      <MDBNavbar style={{backgroundColor:color.primary, flex:1, justifyContent:'space-between'}}>
        <MDBContainer>
          <MDBNavbarBrand href='#'>
          <img
            src={`${Url.Cdm}LogolongB.png`}
            height='30'
            alt=''
            loading='lazy'
          />
          </MDBNavbarBrand>
          <Link to='/' >
                <img
                  src={`${Url.Cdm}i-logout.png`}
                  height='30'
                  alt=''
                  loading='lazy'
                  onClick={()=>dispatch(setUserLoggedIn(null))}
                />
          </Link>
        </MDBContainer>
      </MDBNavbar>
    </>
    )
}

export default Navbar;