import { createSlice } from "@reduxjs/toolkit";


export const usersSlice = createSlice({
    name:'users',
    initialState: {
        jwt:null,
        userLoggedIn: null,
    },
    reducers: {
        setJwt: (state, {payload}) =>{
            state.jwt = payload;
            console.log('jwt :',state.jwt);
        },
        setUserLoggedIn: (state, {payload}) => {
            state.userLoggedIn = payload;
        }
    }
})

export const { setJwt, setUserLoggedIn } = usersSlice.actions;
export default usersSlice.reducer;