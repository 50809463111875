import logo from './logo.svg';
//RTK
import store from './redux/store';
import { Provider } from 'react-redux';
//Navigation
import { BrowserRouter as Router, Route, Routes, redirect } from 'react-router-dom';
//Pages
import Home from './pages/Home';
import LogIn from './pages/LogIn';
import BackOffice from './pages/BackOffice';
import Privacy from './pages/Privacy';
import AccountDeletionRequest from './pages/AccountDeletionRequest';
import './App.css';

const App = () => {


  return (

    <Provider store={store}>

  
        <Routes>
        <Route exact path='/' element={<Home/>}></Route>
        <Route exact path='/LogIn' element={<LogIn/>}></Route>
        <Route exact path='/BackOffice' element={<BackOffice/>}></Route>
        <Route exact path='/privacy' element={<Privacy/>}></Route>
        <Route exact path='/account-deletion-request' element={<AccountDeletionRequest/>}></Route>
        </Routes>


    </Provider>
  );
}

export default App;
