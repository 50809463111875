import { configureStore } from '@reduxjs/toolkit';
import usersReducer from './slices/users.slice';
import placesReducer from './slices/places.slice';

const reducer = {
    users:usersReducer,
    places:placesReducer
}

const store = configureStore({
    reducer:reducer,
    devTools:true,
    middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

export default store;