import { createSlice } from "@reduxjs/toolkit";


export const placesSlice = createSlice({
    name:'places',
    initialState: {
        places: [],
        selectedPlace: {},
        directions: []
    },
    reducers: {
        setPlaces: (state, {payload}) => {
            state.places = payload;
            console.log(state.places);
        },
        setSelectedPlace: (state, {payload}) => { 
            state.selectedPlace = payload;
        },
        setDirections: (state, {payload}) => {
            state.directions = payload;
        }
    }
})

export const { setPlaces, setSelectedPlace, setDirections } = placesSlice.actions;
export default placesSlice.reducer;