import React, { useState } from 'react';
import './css/BackOffice.css';
import Navbar from '../components/Navbar';
import {
    MDBContainer,
    MDBCard,
    MDBCardBody,
    MDBRow,
    MDBCol,
    MDBCardText,
  }
  from 'mdb-react-ui-kit';
import { Alert, Image } from 'react-bootstrap';
  import color from '../constants/color';
  import Url from '../constants/Url';
import FuturaL from '../components/FuturaL';
import { useDispatch, useSelector } from 'react-redux';
import { setDirections } from '../redux/slices/places.slice';
import { useNavigate } from 'react-router-dom';
import {  Button } from 'react-bootstrap';
import Select from 'react-select';
import PlacesAutocomplete, {
    geocodeByAddress,
  } from 'react-places-autocomplete';
import { createNewDirection, deleteDirectionById } from '../redux/services/directions';
import { loadDirectionsByPlace } from '../redux/services/places';


const BackOffice = () => {

    const [ isLoading, setIsloading ] = useState(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();
  
    const jwt = useSelector(state=>state.users.jwt);

    //Selected place
    const [ selectedPlace, setSelectedPlace ] = useState(useSelector(state=>state.places.selectedPlace));
    const [ directionsByPlace, setDirectionsByPlace ] = useState(useSelector(state=>state.places.directions));

    //Form 
    const [ message, setMessage ] = useState('');
    const [ options, setOptions ] = useState([]);
    const [ address, setAddress ] = useState('');
    const [ addressFrom, setAddressFrom ] = useState('');
    const [ fromLat, setFromLat ] = useState(0.00);
    const [ fromLng, setFromLng ] = useState(0.00);
    const [ addressTo, setAddressTo ] = useState('');
    const [ toLat, setToLat ] = useState(0.00);
    const [ toLng, setToLng ] = useState(0.00);

    //Preloaded data
    const user = useSelector(state=>state.users.userLoggedIn);
    const places = useSelector(state=>state.places.places);

    //Data load
    const loadOptions = () =>{

        try {
            var all = places.map((p, index)=>{
                return (
                    {
                    value:p,
                    label:p.libelle
                    }
                )
            });
            setOptions(all);
            getDirectionsByPlace(all[0],jwt);
        } catch (error) {
            navigate('');
            throw error;
        }


    }

    const getDirectionsByPlace = async(p) => {

        try {
            const response = await loadDirectionsByPlace(p, jwt);
            dispatch(setDirections(response)); 
            console.log("directions : ", response);
            setDirectionsByPlace(response);  
        } catch (error) {
            navigate('');
            throw error;
        }

    }

    
    if(isLoading){

        loadOptions();
        setIsloading(false);
    }



  

    //Directions Create & Delete actions
    const createDirections = async() => {
        console.log("createDirections");
        console.log("selected place ", selectedPlace);
            var direction = {
                'addressFrom': addressFrom,
                'originlatitude':fromLat,
                'originlongitude':fromLng,
                'addressTo':addressTo,
                'destinationlatitude':toLat,
                'destinationlongitude':toLng,
                'directionplace':selectedPlace.id
            }
            console.log(direction);

            try {
            const response = await createNewDirection(jwt, direction);

                    console.log(response);

                    // Use the spread operator to create a new array
        setDirectionsByPlace([...directionsByPlace, response]); 

        // Reset form fields
        setAddressFrom('');
        setFromLat(0.00);
        setFromLng(0.00);
        setAddressTo('');
        setToLat(0.00);
        setToLng(0.00); // Fix this to reset toLng

                }
                catch(error){
                
                    setAddressFrom('');
                    setFromLat(0.00);
                    setFromLng(0.00);
                    setAddressTo('');
                    setToLat(0.00);
                    setToLng(0.00); // Fix this to reset toLng

                    navigate('/');
                    throw error;
                }
        
    }

    const directionDeletion = async(d) =>{
        console.log("directiondeletion : "+d.id);
        if(directionsByPlace.length>1){
            await deleteDirectionById(jwt, d.id);
            setDirectionsByPlace(directionsByPlace.filter(dir=>dir.id!==d.id));
        }
        else{
            setMessage("Impossible to delete the last 2 directions of a place");
        }
    }

    //Geocoding for Addresses From & To
    const handleChangeFrom = (address) => {
        setAddressFrom(address);
    };
     
    const handleSelectFrom = (address) => {
        geocodeByAddress(address)
          .then(results => {
            console.log(results);
            setAddressFrom(results[0].formatted_address);
            setFromLat(results[0].geometry.location.lat);
            setFromLng(results[0].geometry.location.lng);
          })
          .then(latLng => console.log('Success', latLng))
          .catch(error => console.error('Error', error));
      };
    
      const handleChangeTo = (address) => {
        setAddressTo(address);
    };
     
    const handleSelectTo = (address) => {
        geocodeByAddress(address)
          .then(results => {
            console.log(results);
            setAddressTo(results[0].formatted_address);
            setToLat(results[0].geometry.location.lat);
            setToLng(results[0].geometry.location.lng);
          })
          .then(latLng => console.log('Success', latLng))
          .catch(error => console.error('Error', error));
      };

const load = () => {
     
    if(user!==null){
        return (
            <div id="root" >
            <Navbar/>
            <MDBContainer  xs='12' md='6' lg='4'>

                <MDBRow className='g-0 align-items-center'>

                    <MDBCol col='6'>

                        <MDBCard className='my-5 cascading-right' style={{background: 'white',  backdropFilter: 'blur(30px)', minWidth:'30vw'}}>
                        <MDBCardBody className='p-5 shadow-5 text-center'>


                            <h2 className="fw-bold mb-5" style={{color:color.primary}}>Directions</h2>
                                            <h5 style={{ color:color.primary, marginBottom:'5vh',
                                            fontSize:'2vh'}}>{message}</h5>

                            <Select options={options}  isMulti={false}  defaultValue={options[0]}
                            onChange={(e)=>getDirectionsByPlace(e)}/>

                            {
                                directionsByPlace.length>0?
                                <MDBRow style={{marginTop:'5vh', marginBottom:'5vh'}}>
                                        <MDBCardText>{selectedPlace.libelle} - Directions ({directionsByPlace.length})</MDBCardText>
                                        <MDBCol>
                                    
                                        
                                        <div class="table-responsive-sm">
                                <table class="table table-hover">
                                <thead>
                                    <tr id='justify'>
                                    <th scope="col"># id</th>
                                    <th scope="col">From</th>
                                    <th scope="col">To</th>
                                    <th scope="col">Delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        directionsByPlace.map((d)=>{
                                            return(
                                                <tr key={d.id} id='justify'>
                                                <th scope="row" >{d.id}</th>
                                                <td>{d.addressfrom}<br/>
                                                Lat : {d.originlatitude}<br/>
                                                Lng : {d.originlongitude}
                                                </td>
                                                <td>{d.addressto}<br/>
                                                Lat : {d.destinationlatitude}<br/>
                                                Lng : {d.destinationlongitude}
                                                </td>
                                                <td>
                                                <Image src={`${Url.Cdm}i-delete.png`} style={{maxHeight:'40px'}}
                                                onClick={()=>directionDeletion(d)}/>
                                                </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                                </table>
                                </div>
                                        
                                    
                                </MDBCol>
                                </MDBRow>
                                :
                                <MDBCardText style={{marginTop:'2vh',color:color.primary}}>No direction set for {selectedPlace.libelle}</MDBCardText>
                            }


                            <hr/>

                            <p id="justify">
                            <span style={{marginBottom:'5vh'}}>FROM</span>
                            
                            <PlacesAutocomplete  id='from'
                            value={addressFrom}
                            onChange={(e)=>handleChangeFrom(e)}
                            onSelect={(e)=>handleSelectFrom(e)}>
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div>
                                <input
                                {...getInputProps({
                                    placeholder: 'Address ...',
                                    className: 'location-search-input',
                                    
                                })}
                                style={{width:'100%', }}
                                />
                                <div className="autocomplete-dropdown-container">
                                {loading && <div>Loading...</div>}
                                {suggestions.map(suggestion => {
                                    const className = suggestion.active
                                    ? 'suggestion-item--active'
                                    : 'suggestion-item';
                                    // inline style for demonstration purpose
                                    const style = suggestion.active
                                    ? { backgroundColor: '#fafafa', cursor: 'pointer', textAlign:'justify' }
                                    : { backgroundColor: '#ffffff', cursor: 'pointer' , textAlign:'justify'};
                                    return (
                                    <div
                                        {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                        })}
                                        
                                    >
                                        <span style={{fontSize:'2vh', color:color.primary, textAlign:'justify'}}>{suggestion.description}</span>
                                    </div>
                                    );
                                })}
                                </div>
                            </div>
                            )}
                            </PlacesAutocomplete>

                            <p id="justify">
                            <FuturaL>Selected address : {addressFrom}</FuturaL><br/>
                            <FuturaL>Lat : {fromLat}</FuturaL><br/>
                            <FuturaL>Lng : {fromLng}</FuturaL><br/></p>
                            </p>

                            <hr/>

                            <p id="justify">
                            <span style={{marginBottom:'5vh'}}>TO</span>

                            <PlacesAutocomplete  id='to'
                            value={addressTo}
                            onChange={(e)=>handleChangeTo(e)}
                            onSelect={(e)=>handleSelectTo(e)}
                            >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div>
                                <input
                                {...getInputProps({
                                    placeholder: 'Address ...',
                                    className: 'location-search-input',
                                    
                                })}
                                style={{width:'100%', }}
                                />
                                <div className="autocomplete-dropdown-container">
                                {loading && <div>Loading...</div>}
                                {suggestions.map(suggestion => {
                                    const className = suggestion.active
                                    ? 'suggestion-item--active'
                                    : 'suggestion-item';
                                    // inline style for demonstration purpose
                                    const style = suggestion.active
                                    ? { backgroundColor: '#fafafa', cursor: 'pointer', textAlign:'justify'}
                                    : { backgroundColor: '#ffffff', cursor: 'pointer' , textAlign:'justify'};
                                    return (
                                    <div
                                        {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                        })}
                                        
                                    >
                                        <span style={{fontSize:'2vh', color:color.primary, textAlign:'justify'}}>{suggestion.description}</span>
                                    </div>
                                    );
                                })}
                                </div>
                            </div>
                            )}
                            </PlacesAutocomplete>

                            <p id="justify">
                            <FuturaL>Selected address : {addressTo}</FuturaL><br/>
                            <FuturaL>Lat : {toLat}</FuturaL><br/>
                            <FuturaL>Lng : {toLng}</FuturaL><br/></p>

                            </p>

                            <hr/>
                            <p id="justify">
                            <span >Once both From & To addresses are selected, you can save the direction.</span>
                            </p>
                            
                            <Button type='button' onClick={()=>createDirections()} 
                            style={{backgroundColor:color.primary, width:'20%'}} 
                            disabled={addressFrom && addressTo ? false : true}>Save</Button>

                            
                        </MDBCardBody>
                        </MDBCard>
                    </MDBCol>


                </MDBRow>

            </MDBContainer>
            </div>
        )
    }else{
        return(
            navigate('/LogIn')
        )
    }
}


    return(

        <div>  
{
    load()
}   
        </div>

    )
}

export default BackOffice;

